import { DateRangePicker } from "react-dates";
import React, { useState } from "react";
import moment from "moment";

export const FilterForm = ({handleSubmit, onSubmit, search, resetFilters, register, setValue}) => {
    const [startDate, setStartDate] = useState(search.dateFrom && moment(search.dateFrom));
    const [endDate, setEndDate] = useState(search.dateTo && moment(search.dateTo));
    const [dateFocus, setDateFocus] = useState();
    return ( <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <label className="form-control-label">Region</label>
                    <select name="region"
                            className="form-control"
                            ref={register}
                            defaultValue={search.region}>
                        <option value="">Select Region</option>
                        <option value="1">Islamabad</option>
                        <option value="2">Lahore</option>
                        <option value="3">Karachi</option>
                    </select>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label className="form-control-label">Sender</label>
                    <input name="senderNo"
                           type="text"
                           placeholder="Sender Phone #"
                           className="form-control"
                           ref={register}
                           defaultValue={search.senderNo}
                    />
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label className="form-control-label">Smart Card No</label>
                    <input name="smartCardNo"
                           type="text"
                           placeholder="Smart Card No"
                           className="form-control"
                           ref={register}
                           defaultValue={search.smartCardNo}
                    />
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label className="form-control-label">Scratch Card Serial No</label>
                    <input name="scratchCardSerialNo"
                           type="text"
                           placeholder="Scratch Card Serial No"
                           className="form-control"
                           ref={register}
                           defaultValue={search.scratchCardSerialNo}
                    />
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label className="form-control-label">Scratch Card Pin No</label>
                    <input name="scratchCardNo"
                           type="text"
                           placeholder="Scratch Card No"
                           className="form-control"
                           ref={register}
                           defaultValue={search.scratchCardNo}
                    />
                </div>
            </div>
            <div className="col-3">
                <div className="form-group">
                    <label className="form-control-label">Received Date Range</label>
                    <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="usedOnStart" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="usedOnEnd" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                            setValue("dateFrom", startDate?.format('YYYY-MM-DD HH:mm:ss'));
                            setValue("dateTo", endDate?.format('YYYY-MM-DD HH:mm:ss'));
                        }} // PropTypes.func.isRequired,
                        enableOutsideDays={true}
                        focusedInput={dateFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => setDateFocus(focusedInput)} // PropTypes.func.isRequired,
                        isOutsideRange={day => false}
                        minimumNights={0}
                    />
                </div>
            </div>
        </div>
        <div className="form-group">
            <input type="submit" value="Filter" className="btn btn-primary"/>
            <input type="button" value="Reset" className="btn btn-secondary ml-2"
                   onClick={resetFilters}/>
        </div>
    </form>);
}