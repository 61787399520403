import { createReducer, createAction } from "@reduxjs/toolkit";

export const userLoggedIn = createAction("USER_LOGGED_IN");
export const userLoggedOut = createAction("USER_LOGGED_OUT");

const reducer = createReducer({
    user: {},
}, builder => {
    builder.addCase(userLoggedIn, (state, action) => {
        state.user = action.payload;
    }).addCase(userLoggedOut, (state, action) => {
        state.user = {
        };
    })
})
export default reducer;
