import React from 'react';
import { Switch, Route } from "react-router-dom";
import './App.css';
import { Layout } from "./components/Layout/Layout";
import { DashboardPage } from "./features/dashboard/DashboardPage";
import { MessagePage } from "./features/message/MessagePage";
import { ScratchCardPage } from "./features/ScratchCard";
import { ScratchCardAuthorizePage } from "./features/ScratchCardAuthorize";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserRoutes } from "./features/users/UserRoutes";

function App() {
    return (
        <Layout>
            <ToastContainer/>
            <Switch>
                <Route path="/" component={DashboardPage} exact={true}/>
                <Route path="/messages" component={MessagePage} exact={true}/>
                <Route path="/users" component={UserRoutes}/>
                <Route path="/scratch-cards" component={ScratchCardPage} exact={true}/>
                <Route path="/scratch-cards/authorize" component={ScratchCardAuthorizePage} exact={true}/>
            </Switch>
        </Layout>
    );
}

export default App;
