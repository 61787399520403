import { fork, takeLatest, call, put } from "redux-saga/effects";
import { userListClear, userListFailure, userListLoaded, userListRequested } from "./userSlice";
import { fetchUsers } from "./userService";

function* getUsers(action) {
    try {
        const { pageNumber, pageLimit, filter } = action.payload;
        const results = yield call(fetchUsers, pageNumber, pageLimit, filter);
        yield put(userListLoaded(results));
    } catch (e) {
        yield put(userListFailure());
        yield put(userListClear());
    }
}

function* watchUserCreate() {
    yield takeLatest(userListRequested.toString(), getUsers);
}

export default [
    fork(watchUserCreate),
];