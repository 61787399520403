import { fork, takeLatest, call, put } from "redux-saga/effects";
import { scratchCardsRequested, scratchCardsLoaded } from "state/actions/scratchCardAction";
import { fetchScratchCards } from "services/scratchCardService";

function* getScratchCards(action) {
    const results = yield call(fetchScratchCards, action.pageNumber, action.pageSize, action.filter);
    yield put(scratchCardsLoaded(results));
}

function* watchScratchCardRequest() {
    yield takeLatest(scratchCardsRequested().type, getScratchCards);
}

export default [
    fork(watchScratchCardRequest),
];