import React from "react";
import ReactApexChart from "react-apexcharts"
import { api } from "services/api";

export class ScratchCardUseByMonthChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                xaxis: {
                    categories: [],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },


        };
    }

    async componentDidMount() {
        let response = await api.get("/dashboard/scratch-cards-monthly").json();
        this.setState(prevState => ({
            series: response.series,
            options: { ...prevState.options, xaxis: { type:"category" ,categories: response.xAxis } }
        }));
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350}/>
            </div>
        );
    }
}