import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import _ from "lodash";
import { messagesRequested } from "./messageSlice";
import { createLoadingSelector } from "state/selectors/loadingSelector";
import Container from "components/Container";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { FilterForm } from "./FilterForm";

export const MessagePage = () => {
    const loadingSelector = createLoadingSelector(['MESSAGES']);
    const messages = useSelector(state => state.messages.sms);
    const messageLoading = useSelector(state => loadingSelector(state));
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let search = queryString.parse(location.search);
    delete search.pageLimit;
    delete search.pageNumber;
    const { register, handleSubmit, reset, setValue } = useForm();
    const [showFilters, setShowFilters] = useState(false);
    const [pageLimit, setPageLimit] = useState(search.pageLimit ? parseInt(search.pageLimit) : 10);
    const [pageNumber, setPageNumber] = useState(search.pageNumber ? parseInt(search.pageNumber) : 1);
    const [filterCount, setFilterCount] = useState(0);
    const [startDate, setStartDate] = useState(search.dateFrom && moment(search.dateFrom));
    const [endDate, setEndDate] = useState(search.dateTo && moment(search.dateTo));
    const [dateFocus, setDateFocus] = useState();
    useEffect(() => {
        register({ name: "dateTo", value: "" });
        register({ name: "dateFrom", value: "" });
    }, [register]);
    const onSubmit = values => {
        let search = { ...values, pageNumber: 1, pageLimit };
        let query = queryString.stringify(search, {
            skipEmptyString: true
        });
        setPageNumber(1);
        dispatch(messagesRequested({ pageNumber: 1, pageLimit, filter: values }));
        history.push({
            pathname: window.location.pathname,
            search: query
        });
        setShowFilters(false);
        let count = 0;
        _.values(values).forEach(item => item && count++)
        setFilterCount(count);
    }
    const resetFilters = () => {
        let query = queryString.stringify({ pageNumber: 1, pageLimit })
        setPageNumber(1);
        dispatch(messagesRequested({ pageNumber: 1, pageLimit }));
        history.push({
            pathname: window.location.pathname,
            search: query
        });
        reset({ region: "" });
        setStartDate(null);
        setEndDate(null);
        setFilterCount(0);
        setShowFilters(false);
    }
    useEffect(() => {
        async function loadMessages() {
            let count = 0;
            _.values(search).forEach(item => item && count++)
            setFilterCount(count);
            search.pageNumber = pageNumber.toString();
            search.pageLimit = pageLimit.toString();
            let query = queryString.stringify(search, { skipEmptyString: true });
            history.push({
                pathname: window.location.pathname,
                search: query
            });
            dispatch(messagesRequested({ pageNumber, pageLimit, filter: search }));
        }

        loadMessages().then();
    }, [pageLimit, pageNumber])
    return (
        <>
            <header className="page-header">
                <div className="container-fluid">
                    <h2 className="no-margin-bottom">Dashboard</h2>
                </div>
            </header>
            <div className="breadcrumb-holder container-fluid">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                    <li className="breadcrumb-item active">Messages</li>
                </ul>
            </div>
            <section className="tables">
                <div className="container-fluid">
                    <div className="card">
                        <div className="table-card-close d-flex mt-2">
                            <span className="mr-1 align-self-center">Items Per page </span>
                            <select
                                className="form-control w-auto"
                                value={pageLimit}
                                onChange={event => {
                                    setPageNumber(1);
                                    setPageLimit(parseInt(event.target.value));
                                }}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <div className="card-header d-flex align-items-center">
                            <h3 className="h4">
                                Messages
                            </h3>
                            <div className="dropdown ml-1">
                                <button type="button" className="btn btn-info btn-sm"
                                        onClick={() => setShowFilters(!showFilters)}>
                                    <i className="las la-filter"/> Filter &nbsp;
                                    {filterCount > 0 && <span
                                        className="badge badge-pill badge-danger">{filterCount}</span>}
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className={clsx("collapse", { "show": showFilters })}>
                                    <div className="card-body">
                                        <FilterForm handleSubmit={handleSubmit} onSubmit={onSubmit} search={search} resetFilters={resetFilters} register={register} setValue={setValue}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <Container isLoading={messageLoading}>
                                    <table className="table table-striped table-hover table-responsive-sm h-100">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Sender</th>
                                            <th>Name</th>
                                            <th>Content</th>
                                            <th>Smart Card No</th>
                                            <th>Scratch Card No</th>
                                            <th>Discount Amount</th>
                                            <th>Region</th>
                                            <th>SMS Type</th>
                                            <th>Is Processed</th>
                                            <th>Recieved On</th>
                                            <th>Reciept No</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {messages.items.length > 0 && messages.items.map(message => (
                                            <tr key={message.id}>
                                                <th scope="row">{message.id}</th>
                                                <td>{message.sender}</td>
                                                <td>{message.smsUsername}</td>
                                                <td>{message.content}</td>
                                                <td>{message.smartCardNo}</td>
                                                <td>{message.scratchCardId ? <Link
                                                    to={`/scratch-cards?pageNumber=1&pageLimit=10&serialNo=${message.scratchCard?.serialNo}`}>{message.scratchCardNo}
                                                </Link> : message.scratchCardNo}
                                                </td>
                                                <td>{message.discountAmount}</td>
                                                <td>{message.region?.name}</td>
                                                <td>{message.smsType}</td>
                                                <td>{message.isProcessed ? "Yes" : "No"}</td>
                                                <td>{moment(message.createdOn).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                <td>{message.smsReceiptId}</td>
                                            </tr>
                                        ))}
                                        {messages.items.length === 0 && (
                                            <tr>
                                                <td colSpan={10} className="text-center">No Records Found</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </Container>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-5">
                                    <div className="pagination-info"
                                         aria-live="polite">
                                        {messages.totalCount > 0 && <>Showing 1 to 10
                                            of {messages.totalCount} entries</>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                    {messages.items.length > 0 &&
                                    <ReactPaginate
                                        previousLabel={'previous'}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        breakClassName={'page-link'}
                                        initialPage={pageNumber - 1}
                                        forcePage={pageNumber - 1}
                                        pageCount={messages.totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={({ selected }) => setPageNumber(selected + 1)}
                                        containerClassName={'pagination'}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        activeClassName={'active'}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};