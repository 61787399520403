import React from 'react';

const Spinner = () => {
    return (
        <div className="d-flex align-content-center justify-content-center clearfix" style={{height:"150px"}}>
            <div className="spinner-border align-self-center text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default Spinner;