import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import clsx from "clsx";
import { toast } from "react-toastify";
import { fetchUser, updateUser } from "./userService";
import { useParams } from "react-router";

const schema = yup.object().shape({
    firstName: yup.string().required("First Name can't be empty!"),
    lastName: yup.string().required("Last Name can't be empty!"),
    password: yup.string(),
    email: yup.string().required("Email can't be empty!"),
    phoneNumber: yup.string().required("Phone number can't be empty!"),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    regionId: yup.number().positive().required("Region is required!"),
    permissions: yup.array().of(yup.string()).min(1, "At least one permission should be selected!"),
});

export const UserEdit = () => {
    const { id } = useParams();
    const [isReadOnly, setIsReadOnly] = useState(true);
    const permissions = ["messages:read", "users:read", "users:create"]
    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        async function getUser() {
            let applicationUser = await fetchUser(id);
            reset(applicationUser);
        }

        getUser().then();
    }, []);
    const onSubmit = values => {
        if(!isReadOnly){
            updateUser(values).then(user => {
                toast.success(`User updated successfully!`);
                reset(user);
            });
        }
    };
    const setEdit = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsReadOnly(false);
    }
    return (<>
        <div className="breadcrumb-holder container-fluid">
            <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                <li className="breadcrumb-item active">Users</li>
                <li className="breadcrumb-item active">Create</li>
            </ul>
        </div>
        <section className="forms">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Create User</h3>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                            <input type="text" readOnly={true} hidden={true} name="id" ref={register}/>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">First Name</label>
                                <div className="col-sm-9">
                                    <input name="firstName"
                                           type="text"
                                           className={clsx("form-control", { "is-invalid": errors.firstName?.message })}
                                           readOnly={isReadOnly}
                                           ref={register}/>
                                    <div className="invalid-feedback">{errors.firstName?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Last Name</label>
                                <div className="col-sm-9">
                                    <input name="lastName"
                                           type="text"
                                           className={clsx("form-control", { "is-invalid": errors.lastName?.message })}
                                           readOnly={isReadOnly}
                                           ref={register}/>
                                    <div className="invalid-feedback">{errors.lastName?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Email</label>
                                <div className="col-sm-9">
                                    <input name="email"
                                           type="email"
                                           readOnly={isReadOnly}
                                           ref={register}
                                           className={clsx("form-control", { "is-invalid": errors.email?.message })}
                                    />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Password</label>
                                <div className="col-sm-9">
                                    <input type="password"
                                           name="password"
                                           readOnly={isReadOnly}
                                           ref={register}
                                           className={clsx("form-control", { "is-invalid": errors.password?.message })}/>
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Confirm Password</label>
                                <div className="col-sm-9">
                                    <input type="password"
                                           name="confirmPassword"
                                           readOnly={isReadOnly}
                                           ref={register}
                                           className={clsx("form-control", { "is-invalid": errors.confirmPassword?.message })}/>
                                    <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Phone Number</label>
                                <div className="col-sm-9">
                                    <input name="phoneNumber"
                                           type="text"
                                           readOnly={isReadOnly}
                                           ref={register}
                                           className={clsx("form-control", { "is-invalid": errors.phoneNumber?.message })}/>
                                    <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-3 form-control-label">Region</label>
                                <div className="col-sm-9">
                                    <select name="regionId"
                                            disabled={isReadOnly}
                                            className={clsx("form-control", { "is-invalid": errors.regionId?.message })}
                                            ref={register}>
                                        <option value="">Select Region</option>
                                        <option value="1">Islamabad</option>
                                        <option value="2">Lahore</option>
                                        <option value="3">Karachi</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.regionId?.message}</div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Permissions</label>
                                <div className="col-sm-9">
                                    {permissions.map((item, index) =>
                                        (<div className="i-checks" key={index}>
                                            <input type="checkbox"
                                                   name="permissions"
                                                   disabled={isReadOnly}
                                                   ref={register}
                                                   value={item}
                                                   className={clsx("checkbox-template", { "is-invalid": errors.regionId?.message })}
                                            />
                                            <label>{item}</label>
                                        </div>))}
                                    <div className="text-danger">{errors.permissions?.message}</div>
                                </div>
                            </div>
                            <div className="line"/>
                            <div className="form-group row">
                                <div className="col-sm-4 offset-sm-3">
                                    {isReadOnly ? <button type="button" className="btn btn-primary"
                                                          onClick={setEdit}>Edit</button> :
                                        <button type="submit" className="btn btn-primary">Save Changes</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>)
}