import React from 'react';
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useAuth } from 'oidc-react';

const Header = ({ navbarClosed, setNavbarClosed }) => {
    const auth = useAuth();
    const logout = async () => {
        await auth.signOut();
        window.location.href = "/connect/logout";
    }
    return (
        <header className="header">
            <nav className="navbar">
                <div className="search-box">
                    <button className="dismiss">
                        <i className="icon-close"/>
                    </button>
                    <form id="searchForm" action="#" role="search">
                        <input type="search" placeholder="What are you looking for..." className="form-control"/>
                    </form>
                </div>
                <div className="container-fluid">
                    <div className="navbar-holder d-flex align-items-center justify-content-between">
                        <div className="navbar-header">
                            <Link to={"/"} className="navbar-brand d-none d-sm-inline-block">
                                <div className="brand-text d-none d-lg-inline-block">
                                    <span>SCPS </span>
                                    <strong>Dashboard</strong>
                                </div>
                                <div className="brand-text d-none d-sm-inline-block d-lg-none">
                                    <strong>BD</strong>
                                </div>
                            </Link>
                            <button className={clsx("menu-btn", { "active": !navbarClosed })}
                                    onClick={() => setNavbarClosed(!navbarClosed)}>
                                <span/>
                                <span/>
                                <span/>
                            </button>
                        </div>
                        <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                            <li className="nav-item">
                                <button onClick={logout} className="nav-link logout">
                                    <span className="d-none d-sm-inline">Logout</span>
                                    <i className="fa fa-sign-out"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export { Header };