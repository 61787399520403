import { api } from "services/api";
import { getAccessToken } from "services/accessTokenService";

export const fetchUsers = async (pageNumber, pageSize, filter) => {
    return await api.get('applicationUser/', {
        searchParams: { pageNumber, pageSize, ...filter },
        headers: {
            "authorization": `Bearer ${getAccessToken()}`,
        }
    }).json();
};
export const createUser = async user => {
    return await api.post("applicationUser/", {
        headers: {
            "authorization": `Bearer ${getAccessToken()}`,
        },
        json: user
    }).json();
};
export const fetchUser = async id => {
    return await api.get(`applicationUser/${id}`, {
        headers: {
            "authorization": `Bearer ${getAccessToken()}`,
        }
    }).json();
};
export const updateUser = async user => {
    return await api.put(`applicationUser/`, {
        json: user,
        headers: {
            "authorization": `Bearer ${getAccessToken()}`
        }
    }).json();
};