import { all } from "redux-saga/effects";
import messageSaga from 'features/message/messageSaga';
import scratchCardSaga from './scratchCardSaga';
import userSaga from "features/users/userSaga";
export default function* rootSaga() {
    yield all([
        ...messageSaga,
        ...scratchCardSaga,
        ...userSaga
    ]);
}
