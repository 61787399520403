import React, { useEffect, useState } from 'react';
import { ScratchCardUseByMonthChart } from "./ScratchCardUseByMonthChart";
import { api } from "services/api";

export const DashboardPage = () => {
    const [rechargesToday, setRechargesToday] = useState(0);
    useEffect(() => {
        async function getRechargesToday() {
            try {
                let response = await api.get("/dashboard/recharges-today").json();
                setRechargesToday(response);

            } catch (e) {
                console.error(e);
            }
        }
        getRechargesToday();
    }, []);
    return (
        <>
            <header className="page-header">
                <div className="container-fluid">
                    <h2 className="no-margin-bottom">Dashboard</h2>
                </div>
            </header>
            <section className="dashboard-counts no-padding-bottom">
                <div className="container-fluid">
                    <div className="row bg-white has-shadow">
                        <div className="col-xl-3 col-sm-6">
                            <div className="item d-flex align-items-center">
                                <div className="icon bg-violet">
                                    <i className="las la-cash-register"/>
                                </div>
                                <div className="title">
                                    <span>Recharges<br/>Today</span>
                                </div>
                                <div className="number"><strong>{rechargesToday}</strong></div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                            <div className="item d-flex align-items-center">
                                <div className="icon bg-red">
                                    <i className="icon-padnote"/>
                                </div>
                                <div className="title">
                                    <span>Work<br/>Orders</span>
                                    <div className="progress">
                                        <div role="progressbar" style={{ width: "70%", height: "4px" }}
                                             aria-valuenow="70"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-red"/>
                                    </div>
                                </div>
                                <div className="number"><strong>70</strong></div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                            <div className="item d-flex align-items-center">
                                <div className="icon bg-green"><i className="icon-bill"/></div>
                                <div className="title">
                                            <span>
                                                New<br/>Invoices
                                            </span>
                                    <div className="progress">
                                        <div role="progressbar" style={{ width: "40%", height: "4px", }}
                                             aria-valuenow="40"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-green"/>
                                    </div>
                                </div>
                                <div className="number">
                                    <strong>40</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                            <div className="item d-flex align-items-center">
                                <div className="icon bg-orange"><i className="icon-check"/></div>
                                <div className="title"><span>Open<br/>Cases</span>
                                    <div className="progress">
                                        <div role="progressbar" style={{ width: "50%", height: "4px" }}
                                             aria-valuenow="50"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-orange"/>
                                    </div>
                                </div>
                                <div className="number"><strong>50</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dashboard-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="statistics col-lg-3 col-12">
                            <div className="statistic d-flex align-items-center bg-white has-shadow">
                                <div className="icon bg-red"><i className="fa fa-tasks"/></div>
                                <div className="text"><strong>234</strong><br/><small>Applications</small></div>
                            </div>
                            <div className="statistic d-flex align-items-center bg-white has-shadow">
                                <div className="icon bg-green"><i className="fa fa-calendar-o"/></div>
                                <div className="text"><strong>152</strong><br/><small>Interviews</small></div>
                            </div>
                            <div className="statistic d-flex align-items-center bg-white has-shadow">
                                <div className="icon bg-orange"><i className="fa fa-paper-plane-o"/></div>
                                <div className="text"><strong>147</strong><br/><small>Forwards</small></div>
                            </div>
                        </div>
                        <div className="chart col-lg-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Scratch Cards Use By Region(current year)</h4>
                                </div>
                                <div className="card-body">
                                    <ScratchCardUseByMonthChart/>
                                </div>
                            </div>
                        </div>
                        <div className="chart col-lg-3 col-12">
                            <div className="bar-chart has-shadow bg-white">
                                <div className="title">
                                    <strong className="text-violet">95%</strong>
                                    <br/>
                                    <small>Current Server Uptime</small>
                                </div>
                                <canvas id="barChartHome"/>
                            </div>
                            <div className="statistic d-flex align-items-center bg-white has-shadow">
                                <div className="icon bg-green">
                                    <i className="fa fa-line-chart"/>
                                </div>
                                <div className="text">
                                    <strong>99.9%</strong>
                                    <br/>
                                    <small>Success Rate</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="projects no-padding-top">
                <div className="container-fluid">
                    <div className="project">
                        <div className="row bg-white has-shadow">
                            <div
                                className="left-col col-lg-6 d-flex align-items-center justify-content-between">
                                <div className="project-title d-flex align-items-center">
                                    <div className="image has-shadow">
                                        <img src={require("assets/img/project-1.jpg")} alt="..."
                                             className="img-fluid"/>
                                    </div>
                                    <div className="text">
                                        <h3 className="h4">Project Title</h3>
                                        <small>Lorem Ipsum Dolor</small>
                                    </div>
                                </div>
                                <div className="project-date">
                                    <span className="hidden-sm-down">Today at 4:24 AM</span>
                                </div>
                            </div>
                            <div className="right-col col-lg-6 d-flex align-items-center">
                                <div className="time">
                                    <i className="fa fa-clock-o"/>
                                    12:00 PM
                                </div>
                                <div className="comments">
                                    <i className="fa fa-comment-o"/>20
                                </div>
                                <div className="project-progress">
                                    <div className="progress">
                                        <div role="progressbar" style={{ width: "45%", height: "6px" }}
                                             aria-valuenow="25"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-red"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project">
                        <div className="row bg-white has-shadow">
                            <div
                                className="left-col col-lg-6 d-flex align-items-center justify-content-between">
                                <div className="project-title d-flex align-items-center">
                                    <div className="image has-shadow">
                                        <img src={require("assets/img/project-2.jpg")} alt="..."
                                             className="img-fluid"/>
                                    </div>
                                    <div className="text">
                                        <h3 className="h4">Project Title</h3><small>Lorem Ipsum Dolor</small>
                                    </div>
                                </div>
                                <div className="project-date"><span
                                    className="hidden-sm-down">Today at 4:24 AM</span>
                                </div>
                            </div>
                            <div className="right-col col-lg-6 d-flex align-items-center">
                                <div className="time"><i className="fa fa-clock-o"/>12:00 PM</div>
                                <div className="comments"><i className="fa fa-comment-o"/>20</div>
                                <div className="project-progress">
                                    <div className="progress">
                                        <div role="progressbar"
                                             style={{ width: "60%", height: "6px" }}
                                             aria-valuenow="25"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-green"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project">
                        <div className="row bg-white has-shadow">
                            <div
                                className="left-col col-lg-6 d-flex align-items-center justify-content-between">
                                <div className="project-title d-flex align-items-center">
                                    <div className="image has-shadow">
                                        <img src={require("assets/img/project-3.jpg")} alt="..."
                                             className="img-fluid"/>
                                    </div>
                                    <div className="text">
                                        <h3 className="h4">Project Title</h3><small>Lorem Ipsum Dolor</small>
                                    </div>
                                </div>
                                <div className="project-date"><span
                                    className="hidden-sm-down">Today at 4:24 AM</span>
                                </div>
                            </div>
                            <div className="right-col col-lg-6 d-flex align-items-center">
                                <div className="time"><i className="fa fa-clock-o"/>12:00 PM</div>
                                <div className="comments"><i className="fa fa-comment-o"/>20</div>
                                <div className="project-progress">
                                    <div className="progress">
                                        <div role="progressbar" style={{ width: "50%", height: "6px" }}
                                             aria-valuenow="25"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-violet"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project">
                        <div className="row bg-white has-shadow">
                            <div
                                className="left-col col-lg-6 d-flex align-items-center justify-content-between">
                                <div className="project-title d-flex align-items-center">
                                    <div className="image has-shadow">
                                        <img src={require("assets/img/project-4.jpg")} alt="..."
                                             className="img-fluid"/>
                                    </div>
                                    <div className="text">
                                        <h3 className="h4">Project Title</h3><small>Lorem Ipsum Dolor</small>
                                    </div>
                                </div>
                                <div className="project-date"><span
                                    className="hidden-sm-down">Today at 4:24 AM</span>
                                </div>
                            </div>
                            <div className="right-col col-lg-6 d-flex align-items-center">
                                <div className="time"><i className="fa fa-clock-o"/>12:00 PM</div>
                                <div className="comments"><i className="fa fa-comment-o"/>20</div>
                                <div className="project-progress">
                                    <div className="progress">
                                        <div role="progressbar" style={{ width: "50%", height: "6px" }}
                                             aria-valuenow="25"
                                             aria-valuemin="0" aria-valuemax="100"
                                             className="progress-bar bg-orange"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="client no-padding-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="work-amount card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard1" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/></button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h3>Work Hours</h3><small>Lorem ipsum dolor sit amet.</small>
                                    <div className="chart text-center">
                                        <div className="text"><strong>90</strong><br/><span>Hours</span></div>
                                        <canvas id="pieChart"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="client card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard2" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/></button>
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                    <div className="client-avatar">
                                        <img src={require("assets/img/avatar-2.jpg")} alt="..."
                                             className="img-fluid rounded-circle"/>
                                        <div className="status bg-green"/>
                                    </div>
                                    <div className="client-title">
                                        <h3>Jason Doe</h3><span>Web Developer</span><a href="#">Follow</a>
                                    </div>
                                    <div className="client-info">
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>20</strong><br/>
                                                <small>Photos</small>
                                            </div>
                                            <div className="col-4">
                                                <strong>54</strong><br/>
                                                <small>Videos</small>
                                            </div>
                                            <div className="col-4">
                                                <strong>235</strong><br/>
                                                <small>Tasks</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="client-social d-flex justify-content-between">
                                        <a href="#" target="_blank">
                                            <i className="fa fa-facebook"/>
                                        </a>
                                        <a href="#" target="_blank">
                                            <i className="fa fa-twitter"/>
                                        </a>
                                        <a href="#" target="_blank">
                                            <i className="fa fa-google-plus"/>
                                        </a>
                                        <a href="#" target="_blank">
                                            <i className="fa fa-instagram"/>
                                        </a>
                                        <a href="#" target="_blank">
                                            <i className="fa fa-linkedin"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="overdue card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard3" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/>
                                        </button>
                                        <div aria-labelledby="closeCard3"
                                             className="dropdown-menu dropdown-menu-right has-shadow"><a
                                            href="#"
                                            className="dropdown-item remove">
                                            <i className="fa fa-times"/>
                                            Close
                                        </a>
                                            <a href="#" className="dropdown-item edit">
                                                <i className="fa fa-gear"/>Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h3>Total Overdue</h3><small>Lorem ipsum dolor sit amet.</small>
                                    <div className="number text-center">$20,000</div>
                                    <div className="chart">
                                        <canvas id="lineChart1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feeds no-padding-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="articles card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard4" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/>
                                        </button>
                                        <div aria-labelledby="closeCard4"
                                             className="dropdown-menu dropdown-menu-right has-shadow">
                                            <a href="#" className="dropdown-item remove">
                                                <i className="fa fa-times"/>Close
                                            </a>
                                            <a href="#" className="dropdown-item edit">
                                                <i className="fa fa-gear"/>Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header d-flex align-items-center">
                                    <h2 className="h3">Trending Articles </h2>
                                    <div className="badge badge-rounded bg-green">4 New</div>
                                </div>
                                <div className="card-body no-padding">
                                    <div className="item d-flex align-items-center">
                                        <div className="image">
                                            <img src={require("assets/img/avatar-1.jpg")}
                                                 alt="..." className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="text"><a href="#">
                                            <h3 className="h5">Lorem Ipsum Dolor</h3>
                                        </a>
                                            <small>Posted on 5th
                                                June 2017
                                                by Aria Smith. </small>
                                        </div>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <div className="image">
                                            <img src={require("assets/img/avatar-2.jpg")}
                                                 alt="..."
                                                 className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="text">
                                            <a href="#">
                                                <h3 className="h5">Lorem Ipsum Dolor</h3>
                                            </a>
                                            <small>
                                                Posted on 5th
                                                June 2017
                                                by Frank Williams.
                                            </small>
                                        </div>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <div className="image">
                                            <img src={require("assets/img/avatar-3.jpg")}
                                                 alt="..." className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="text">
                                            <a href="#">
                                                <h3 className="h5">Lorem Ipsum Dolor</h3>
                                            </a>
                                            <small>
                                                Posted on
                                                5th
                                                June 2017
                                                by Ashley Wood.
                                            </small>
                                        </div>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <div className="image">
                                            <img src={require("assets/img/avatar-4.jpg")}
                                                 alt="..." className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="text">
                                            <a href="#">
                                                <h3 className="h5">Lorem Ipsum Dolor</h3>
                                            </a>
                                            <small>
                                                Posted on 5th
                                                June 2017
                                                by Jason Doe.
                                            </small>
                                        </div>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <div className="image">
                                            <img src={require("assets/img/avatar-5.jpg")}
                                                 alt="..." className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="text">
                                            <a href="#">
                                                <h3 className="h5">Lorem Ipsum Dolor</h3>
                                            </a>
                                            <small>
                                                Posted on 5th
                                                June 2017
                                                by Sam Martinez.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="checklist card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard5" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/>
                                        </button>
                                        <div aria-labelledby="closeCard5"
                                             className="dropdown-menu dropdown-menu-right has-shadow">
                                            <a
                                                href="#"
                                                className="dropdown-item remove">
                                                <i className="fa fa-times"/>Close
                                            </a>
                                            <a href="#"
                                               className="dropdown-item edit">
                                                <i className="fa fa-gear"/>Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header d-flex align-items-center">
                                    <h2 className="h3">To Do List </h2>
                                </div>
                                <div className="card-body no-padding">
                                    <div className="item d-flex">
                                        <input type="checkbox" id="input-1" name="input-1"
                                               className="checkbox-template"/>
                                        <label htmlFor="input-1">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </label>
                                    </div>
                                    <div className="item d-flex">
                                        <input type="checkbox" id="input-2" name="input-2"
                                               className="checkbox-template"/>
                                        <label htmlFor="input-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </label>
                                    </div>
                                    <div className="item d-flex">
                                        <input type="checkbox" id="input-3" name="input-3"
                                               className="checkbox-template"/>
                                        <label htmlFor="input-3">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </label>
                                    </div>
                                    <div className="item d-flex">
                                        <input type="checkbox" id="input-4" name="input-4"
                                               className="checkbox-template"/>
                                        <label htmlFor="input-4">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </label>
                                    </div>
                                    <div className="item d-flex">
                                        <input type="checkbox" id="input-5" name="input-5"
                                               className="checkbox-template"/>
                                        <label htmlFor="input-5">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </label>
                                    </div>
                                    <div className="item d-flex">
                                        <input type="checkbox" id="input-6" name="input-6"
                                               className="checkbox-template"/>
                                        <label htmlFor="input-6">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="updates no-padding-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="recent-updates card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard6" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/>
                                        </button>
                                        <div aria-labelledby="closeCard6"
                                             className="dropdown-menu dropdown-menu-right has-shadow">
                                            <a href="#" className="dropdown-item remove">
                                                <i className="fa fa-times"/>
                                                Close
                                            </a>
                                            <a href="#" className="dropdown-item edit">
                                                <i className="fa fa-gear"/>Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h3 className="h4">Recent Updates</h3>
                                </div>
                                <div className="card-body no-padding">
                                    <div className="item d-flex justify-content-between">
                                        <div className="info d-flex">
                                            <div className="icon">
                                                <i className="icon-rss-feed"/>
                                            </div>
                                            <div className="title">
                                                <h5>Lorem ipsum dolor sit amet.</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit
                                                    sed.</p>
                                            </div>
                                        </div>
                                        <div className="date text-right">
                                            <strong>24</strong>
                                            <span>May</span>
                                        </div>
                                    </div>
                                    <div className="item d-flex justify-content-between">
                                        <div className="info d-flex">
                                            <div className="icon">
                                                <i className="icon-rss-feed"/>
                                            </div>
                                            <div className="title">
                                                <h5>Lorem ipsum dolor sit amet.</h5>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit
                                                    sed.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="date text-right">
                                            <strong>24</strong>
                                            <span>May</span>
                                        </div>
                                    </div>
                                    <div className="item d-flex justify-content-between">
                                        <div className="info d-flex">
                                            <div className="icon">
                                                <i className="icon-rss-feed"/>
                                            </div>
                                            <div className="title">
                                                <h5>Lorem ipsum dolor sit amet.</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit
                                                    sed.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="date text-right">
                                            <strong>24</strong>
                                            <span>May</span>
                                        </div>
                                    </div>
                                    <div className="item d-flex justify-content-between">
                                        <div className="info d-flex">
                                            <div className="icon">
                                                <i className="icon-rss-feed"/>
                                            </div>
                                            <div className="title">
                                                <h5>Lorem ipsum dolor sit amet.</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit
                                                    sed.</p>
                                            </div>
                                        </div>
                                        <div className="date text-right">
                                            <strong>24</strong>
                                            <span>May</span>
                                        </div>
                                    </div>
                                    <div className="item d-flex justify-content-between">
                                        <div className="info d-flex">
                                            <div className="icon">
                                                <i className="icon-rss-feed"/>
                                            </div>
                                            <div className="title">
                                                <h5>Lorem ipsum dolor sit amet.</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit
                                                    sed.</p>
                                            </div>
                                        </div>
                                        <div className="date text-right">
                                            <strong>24</strong>
                                            <span>May</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="daily-feeds card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard7" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/>
                                        </button>
                                        <div aria-labelledby="closeCard7"
                                             className="dropdown-menu dropdown-menu-right has-shadow">
                                            <a href="#" className="dropdown-item remove">
                                                <i className="fa fa-times"/>
                                                Close
                                            </a>
                                            <a href="#" className="dropdown-item edit">
                                                <i className="fa fa-gear"/>Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h3 className="h4">Daily Feeds</h3>
                                </div>
                                <div className="card-body no-padding">
                                    <div className="item">
                                        <div className="feed d-flex justify-content-between">
                                            <div className="feed-body d-flex justify-content-between">
                                                <a href="#"
                                                   className="feed-profile">
                                                    <img src={require("assets/img/avatar-5.jpg")}
                                                         alt="person"
                                                         className="img-fluid rounded-circle"/>
                                                </a>
                                                <div className="content">
                                                    <h5>Aria Smith</h5>
                                                    <span>Posted a new blog </span>
                                                    <div className="full-date">
                                                        <small>Today 5:60 pm - 12.06.2014</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="date text-right">
                                                <small>5min ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="feed d-flex justify-content-between">
                                            <div className="feed-body d-flex justify-content-between">
                                                <a href="#"
                                                   className="feed-profile">
                                                    <img src={require("assets/img/avatar-2.jpg")}
                                                         alt="person"
                                                         className="img-fluid rounded-circle"/>
                                                </a>
                                                <div className="content">
                                                    <h5>Frank Williams</h5>
                                                    <span>Posted a new blog </span>
                                                    <div className="full-date">
                                                        <small>Today 5:60 pm - 12.06.2014</small>
                                                    </div>
                                                    <div className="CTAs">
                                                        <a href="#" className="btn btn-xs btn-secondary">
                                                            <i className="fa fa-thumbs-up"> </i>Like
                                                        </a>
                                                        <a href="#" className="btn btn-xs btn-secondary">
                                                            <i className="fa fa-heart"> </i>Love
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="date text-right"><small>5min ago</small></div>
                                        </div>
                                    </div>
                                    <div className="item clearfix">
                                        <div className="feed d-flex justify-content-between">
                                            <div className="feed-body d-flex justify-content-between">
                                                <a href="#"
                                                   className="feed-profile">
                                                    <img src={require("assets/img/avatar-3.jpg")}
                                                         alt="person"
                                                         className="img-fluid rounded-circle"/>
                                                </a>
                                                <div className="content">
                                                    <h5>Ashley Wood</h5>
                                                    <span>Posted a new blog </span>
                                                    <div className="full-date">
                                                        <small>Today 5:60 pm - 12.06.2014</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="date text-right">
                                                <small>5min ago</small>
                                            </div>
                                        </div>
                                        <div className="quote has-shadow">
                                            <small>Lorem Ipsum is simply dummy
                                                text of the
                                                printing and typesetting industry. Lorem Ipsum has been the
                                                industry's
                                                standard dummy text ever since the 1500s. Over the
                                                years.
                                            </small>
                                        </div>
                                        <div className="CTAs pull-right">
                                            <a href="#" className="btn btn-xs btn-secondary">
                                                <i className="fa fa-thumbs-up"> </i>
                                                Like
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="recent-activities card">
                                <div className="card-close">
                                    <div className="dropdown">
                                        <button type="button" id="closeCard8" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                                className="dropdown-toggle">
                                            <i className="fa fa-ellipsis-v"/>
                                        </button>
                                        <div aria-labelledby="closeCard8"
                                             className="dropdown-menu dropdown-menu-right has-shadow">
                                            <a href="#"
                                               className="dropdown-item remove">
                                                <i className="fa fa-times"/>Close
                                            </a>
                                            <a href="#" className="dropdown-item edit">
                                                <i className="fa fa-gear"/>Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h3 className="h4">Recent Activities</h3>
                                </div>
                                <div className="card-body no-padding">
                                    <div className="item">
                                        <div className="row">
                                            <div className="col-4 date-holder text-right">
                                                <div className="icon">
                                                    <i className="icon-clock"/>
                                                </div>
                                                <div className="date">
                                                    <span>6:00 am</span>
                                                    <br/>
                                                    <span className="text-info">6 hours ago</span>
                                                </div>
                                            </div>
                                            <div className="col-8 content">
                                                <h5>Meeting</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                                    do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim
                                                    ad minim veniam, quis nostrud.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="row">
                                            <div className="col-4 date-holder text-right">
                                                <div className="icon">
                                                    <i className="icon-clock"/>
                                                </div>
                                                <div className="date">
                                                    <span>6:00 am</span>
                                                    <br/>
                                                    <span className="text-info">6 hours ago</span></div>
                                            </div>
                                            <div className="col-8 content">
                                                <h5>Meeting</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                                    do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim
                                                    ad minim veniam, quis nostrud.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="row">
                                            <div className="col-4 date-holder text-right">
                                                <div className="icon"><
                                                    i className="icon-clock"/>
                                                </div>
                                                <div className="date">
                                                    <span>6:00 am</span>
                                                    <br/>
                                                    <span className="text-info">6 hours ago</span>
                                                </div>
                                            </div>
                                            <div className="col-8 content">
                                                <h5>Meeting</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                                    do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim
                                                    ad minim veniam, quis nostrud.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>);
};