export const SCRATCH_CARDS_REQUEST = "SCRATCH_CARDS_REQUEST";
export const SCRATCH_CARDS_SUCCESS = "SCRATCH_CARDS_SUCCESS";

export const scratchCardsRequested = (pageNumber, pageSize, filter) => ({
    type: SCRATCH_CARDS_REQUEST,
    pageNumber,
    pageSize,
    filter
});
export const scratchCardsLoaded = messages => ({
    type: SCRATCH_CARDS_SUCCESS,
    messages
});

