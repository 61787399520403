import React from "react";
import { Route, Switch } from "react-router";
import { UserList } from "./UserList";
import { UserCreate } from "./UserCreate";
import { UserEdit } from "./UserEdit";

export const UserRoutes = ({ match }) => {
    return (<>
        <header className="page-header">
            <div className="container-fluid">
                <h2 className="no-margin-bottom">Users</h2>
            </div>
        </header>
        <Switch>
            <Route path={`${match.url}/`} component={UserList} exact={true}/>
            <Route path={`${match.url}/create`} component={UserCreate}/>
            <Route path={`${match.url}/:id`} component={UserEdit}/>
        </Switch>
    </>);
}