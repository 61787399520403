import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { scratchCardsRequested } from "state/actions/scratchCardAction";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { DateRangePicker } from 'react-dates';
import { createLoadingSelector } from "state/selectors/loadingSelector";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import moment from "moment";
import Container from "../../components/Container";

export const ScratchCardPage = () => {
    const loadingSelector = createLoadingSelector(['SCRATCH_CARDS']);
    const cards = useSelector(state => state.scratchCards.pagedList);
    const scratchCardsLoading = useSelector(state => loadingSelector(state));
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    let search = queryString.parse(location.search);
    const { register, handleSubmit, reset, setValue } = useForm();
    useEffect(() => {
        register({ name: "usedOnStart", value: "" });
        register({ name: "usedOnEnd", value: "" });
        register({ name: "expiresOnStart", value: "" });
        register({ name: "expiresOnEnd", value: "" });
    }, [register]);
    const [showFilters, setShowFilters] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(search.pageLimit ? parseInt(search.pageLimit) : 10);
    const [pageNumber, setPageNumber] = useState(search.pageNumber ? parseInt(search.pageNumber) : 1);
    const [usedOnStart, setUsedOnStart] = useState();
    const [usedOnEnd, setUsedOnEnd] = useState();
    const [expiresOnStart, setExpiresOnStart] = useState();
    const [expiresOnEnd, setExpiresOnEnd] = useState();
    const [usedOnFocus, setUsedOnFocus] = useState(null);
    const [expiresOnFocus, setExpiresOnFocus] = useState(null);
    const onSubmit = values => {
        search = queryString.parse(location.search);
        setShowFilters(false);
        let count = 0;
        _.values(values).forEach(item => item && count++)
        setFilterCount(count);
        let searchParams = { ...values, pageNumber: 1, pageLimit };
        let query = queryString.stringify(searchParams, {
            skipEmptyString: true
        });
        setPageNumber(1);
        dispatch(scratchCardsRequested(1, pageLimit, values));
        history.push({
            pathname: window.location.pathname,
            search: query
        });
        setUsedOnStart(null);
        setUsedOnEnd(null);
    }
    const resetFilters = () => {
        let query = queryString.stringify({ pageNumber: 1, pageLimit })
        setPageNumber(1);
        dispatch(scratchCardsRequested(1, pageLimit));
        history.push({
            pathname: window.location.pathname,
            search: query
        });
        reset({});
        setFilterCount(0);
        setShowFilters(false);
    }
    useEffect(() => {
        async function loadScratchCards() {
            let count = 0;
            _.keys(search).forEach(key => (key !== "pageNumber" && key !== "pageLimit") && search[key] && count++);
            setFilterCount(count);
            if (search.pageNumber == pageNumber && search.pageLimit == pageLimit) {
                dispatch(scratchCardsRequested(pageNumber, pageLimit, search));
                return;
            }
            search.pageNumber = pageNumber.toString();
            search.pageLimit = pageLimit.toString();
            let query = queryString.stringify(search, { skipEmptyString: true });
            history.push({
                pathname: window.location.pathname,
                search: query
            });
            dispatch(scratchCardsRequested(pageNumber, pageLimit, search));
        }

        loadScratchCards().then();
    }, [pageLimit, pageNumber])
    return (
        <>
            <header className="page-header">
                <div className="container-fluid">
                    <h2 className="no-margin-bottom">Dashboard</h2>
                </div>
            </header>
            <div className="breadcrumb-holder container-fluid">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                    <li className="breadcrumb-item active">Scratch Cards</li>
                </ul>
            </div>
            <section className="tables">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="table-card-close d-flex mt-2">
                                    <span className="mr-1 align-self-center">Items Per page </span>
                                    <select
                                        className="form-control w-auto"
                                        value={pageLimit}
                                        onChange={event => {
                                            setPageNumber(1);
                                            setPageLimit(parseInt(event.target.value));
                                        }}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                    </select>
                                </div>
                                <div className="card-header d-flex align-items-center">
                                    <h3 className="h4">Scratch Cards</h3>
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-link"
                                                onClick={() => setShowFilters(!showFilters)}>
                                            <i className="las la-filter"/>
                                            {filterCount > 0 && <span
                                                className="badge badge-pill badge-primary">{filterCount}</span>}
                                        </button>
                                        <div className={clsx("dropdown-menu dropdown-filter", { "show": showFilters })}
                                             aria-labelledby="dropdownMenuButton">
                                            <form className="px-4 py-3" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group">
                                                    <label className="form-control-label">Region</label>
                                                    <select name="region"
                                                            className="form-control"
                                                            ref={register}
                                                            defaultValue={search.region}>
                                                        <option value="">Select Region</option>
                                                        <option value="1">Islamabad</option>
                                                        <option value="2">Lahore</option>
                                                        <option value="3">Karachi</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-control-label">Serial Number</label>
                                                    <input name="serialNo"
                                                           type="text"
                                                           placeholder="Serial Number"
                                                           className="form-control"
                                                           ref={register}
                                                           defaultValue={search.serialNo}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-control-label">Pin No</label>
                                                    <input name="pinNo"
                                                           type="text"
                                                           placeholder="Scratch Card Pin"
                                                           className="form-control"
                                                           ref={register}
                                                           defaultValue={search.pinNo}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-control-label">Used On</label>
                                                    <DateRangePicker
                                                        startDate={usedOnStart} // momentPropTypes.momentObj or null,
                                                        startDateId="usedOnStart" // PropTypes.string.isRequired,
                                                        endDate={usedOnEnd} // momentPropTypes.momentObj or null,
                                                        endDateId="usedOnEnd" // PropTypes.string.isRequired,
                                                        onDatesChange={({ startDate, endDate }) => {
                                                            setUsedOnStart(startDate);
                                                            setUsedOnEnd(endDate);
                                                            setValue("usedOnStart", startDate?.format('YYYY-MM-DD HH:mm:ss'));
                                                            setValue("usedOnEnd", endDate?.format('YYYY-MM-DD HH:mm:ss'))
                                                        }} // PropTypes.func.isRequired,
                                                        enableOutsideDays={true}
                                                        focusedInput={usedOnFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                        onFocusChange={focusedInput => setUsedOnFocus(focusedInput)} // PropTypes.func.isRequired,
                                                        isOutsideRange={day => false}
                                                        minimumNights={0}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-control-label">Used On</label>
                                                    <DateRangePicker
                                                        startDate={expiresOnStart} // momentPropTypes.momentObj or null,
                                                        startDateId="expiresOnStart" // PropTypes.string.isRequired,
                                                        endDate={expiresOnEnd} // momentPropTypes.momentObj or null,
                                                        endDateId="expiresOnEnd" // PropTypes.string.isRequired,
                                                        onDatesChange={({ startDate, endDate }) => {
                                                            setExpiresOnStart(startDate);
                                                            setExpiresOnEnd(endDate);
                                                            setValue("expiresOnStart", startDate?.format('YYYY-MM-DD HH:mm:ss'));
                                                            setValue("expiresOnEnd", endDate?.format('YYYY-MM-DD HH:mm:ss'))
                                                        }} // PropTypes.func.isRequired,
                                                        enableOutsideDays={true}
                                                        focusedInput={expiresOnFocus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                        onFocusChange={focusedInput => setExpiresOnFocus(focusedInput)} // PropTypes.func.isRequired,
                                                        isOutsideRange={day => false}
                                                        minimumNights={0}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input type="submit" value="Filter" className="btn btn-primary"/>
                                                    <input type="button" value="Reset"
                                                           className="btn btn-secondary ml-2"
                                                           onClick={resetFilters}/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <Container isLoading={scratchCardsLoading}>
                                            <table className="table table-striped table-hover table-responsive-sm">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Serial No</th>
                                                    <th>Pin No</th>
                                                    <th>Batch No</th>
                                                    <th>Used On</th>
                                                    <th>Expires On</th>
                                                    <th>Amount</th>
                                                    <th>Region</th>
                                                    <th>Status</th>
                                                    <th>Created On</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {cards.items.length > 0 && cards.items.map(card => (
                                                    <tr key={card.id}>
                                                        <th scope="row">{card.id}</th>
                                                        <td>{card.serialNo}</td>
                                                        <td>{card.pinNo}</td>
                                                        <td>{card.batchNo}</td>
                                                        <td>{card.usedOn && moment(card.usedOn).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                        <td>{moment(card.expiresOn).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                        <td>{card.amount}</td>
                                                        <td>{card.region?.name}</td>
                                                        <td>{card.status}</td>
                                                        <td>{card.createdOn}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Container>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="pagination-info" aria-live="polite">
                                                {cards.items.length > 0 && `Showing 1 to 10 of ${cards.totalCount} entries`}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            {cards.items.length > 0 &&
                                            <ReactPaginate
                                                previousLabel={'previous'}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                nextLabel={'next'}
                                                breakLabel={'...'}
                                                breakClassName={'page-link'}
                                                initialPage={pageNumber - 1}
                                                forcePage={pageNumber - 1}
                                                pageCount={cards.totalPages}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={({ selected }) => setPageNumber(selected + 1)}
                                                containerClassName={'pagination'}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                activeClassName={'active'}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};