import { fork, takeLatest, call, put } from "redux-saga/effects";
import { messagesRequested, messagesLoaded, messagesFailure, messagesClear } from "./messageSlice";
import { fetchMessages } from "services/messageService";

function* getMessages(action) {
    try {
        const { pageNumber, pageLimit, filter } = action.payload;
        const results = yield call(fetchMessages, pageNumber, pageLimit, filter);
        yield put(messagesLoaded(results));
    } catch (e) {
        yield put(messagesFailure());
        yield put(messagesClear());
    }
}

function* watchMessageRequest() {
    yield takeLatest(messagesRequested.toString(), getMessages);
}

export default [
    fork(watchMessageRequest),
];