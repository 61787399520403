import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import clsx from "clsx";
import { toast } from "react-toastify";
import { authorizeScratchCards } from "services/scratchCardService";

const schema = yup.object().shape({
    serialFrom: yup
        .number()
        .typeError("Serial From should be a number")
        .integer("Serial From should be a number")
        .max(yup.ref("serialTo"), "Serial From should be less than or equal to Serial To.")
        .required("Serial Range is required."),
    serialTo: yup
        .number()
        .typeError("Serial To should be a number")
        .integer("Serial To should be a number")
        .min(yup.ref("serialFrom"), "Serial To should be greater than or equal to Serial From.")
        .required("Serial Range is required."),
    expiresOn: yup
        .date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .notRequired(),
    dealer: yup
        .string()
        .required("Dealer name is required.")
});

export const ScratchCardAuthorizePage = () => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const { handleSubmit, errors, register, getValues, reset } = useForm({
            resolver: yupResolver(schema),
        });
        const submitData = async () => {
            setIsModalOpen(false);
            const values = getValues();
            const res = await authorizeScratchCards(values);
            toast.success(`${res.recordsUpdated} Scratch Cards update!`);
            reset();
        }
        const onSubmit = values => {
            setIsModalOpen(true);
        };
        return (
            <>
                <header className="page-header">
                    <div className="container-fluid">
                        <h2 className="no-margin-bottom">Scratch Card Authorize</h2>
                    </div>
                </header>
                <div className="breadcrumb-holder container-fluid">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                        <li className="breadcrumb-item active"><Link to={"/scratch-cards"}>Scratch Cards</Link></li>
                        <li className="breadcrumb-item active">Authorize</li>
                    </ul>
                </div>
                <section className="forms">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-close">
                                        <div className="dropdown">
                                            <button type="button" id="closeCard1" data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false" className="dropdown-toggle">
                                                <i className="fa fa-ellipsis-v"/>
                                            </button>
                                            <div aria-labelledby="closeCard1"
                                                 className="dropdown-menu dropdown-menu-right has-shadow">
                                                <a href="#" className="dropdown-item remove">
                                                    <i className="fa fa-times"/>Close
                                                </a>
                                                <a href="#" className="dropdown-item edit">
                                                    <i className="fa fa-gear"/>Edit
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-header d-flex align-items-center">
                                        <h3 className="h4">Authorize Scratch Cards</h3>
                                    </div>
                                    <div className="card-body">
                                        <p>Enter Serial Number Range for Authorization</p>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Serial From</label>
                                                        <input type="number"
                                                               name="serialFrom"
                                                               placeholder="Serial From"
                                                               className={clsx("form-control", { "is-invalid": errors.serialFrom })}
                                                               ref={register()}/>
                                                        {errors.serialFrom && <div
                                                            className="invalid-feedback">{errors.serialFrom?.message}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Serial To</label>
                                                        <input name="serialTo"
                                                               type="number"
                                                               placeholder="Serial To"
                                                               className={clsx("form-control", { "is-invalid": errors.serialTo })}
                                                               ref={register()}/>
                                                        {errors.serialTo && <div
                                                            className="invalid-feedback">{errors.serialTo?.message}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Expiry Date(Leave empty if not
                                                            updating)</label>
                                                        <input name="expiresOn"
                                                               type="datetime-local"
                                                               placeholder="Expiry Date"
                                                               className={clsx("form-control", { "is-invalid": errors.expiresOn })}
                                                               ref={register}/>
                                                        {errors.expiresOn && <div
                                                            className="invalid-feedback">{errors.expiresOn?.message}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Dealer</label>
                                                        <input name="dealer"
                                                               type="text"
                                                               placeholder="Dealer"
                                                               className={clsx("form-control", { "is-invalid": errors.expiresOn })}
                                                               ref={register}/>
                                                        {errors.expiresOn && <div
                                                            className="invalid-feedback">{errors.dealer?.message}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group">
                                                <input type="submit" value="Authorize" className="btn btn-primary"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {isModalOpen && <>
                    <div className="modal-backdrop fade show"/>
                    <div className={clsx("modal fade show", { "d-block": isModalOpen })} tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Authorization Warning</h5>
                                    <button type="button" className="close" aria-label="Close"
                                            onClick={() => setIsModalOpen(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    You are going to authorize {(getValues().serialTo - getValues().serialFrom) + 1} cards.
                                    Do you want to proceed??
                                </div>
                                <div className="modal-footer">
                                    <button type="button"
                                            className="btn btn-secondary"
                                            onClick={() => setIsModalOpen(false)}>
                                        No
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={submitData}>
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </>
        );
    }
;

