import { SCRATCH_CARDS_SUCCESS } from "../actions/scratchCardAction";

const initialState = {
    pagedList: {
        pageIndex: 0,
        totalPages: 0,
        totalCount: 0,
        items: []
    },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SCRATCH_CARDS_SUCCESS:
            return { ...state, pagedList: action.messages };
        default:
            return state;
    }
}