import React from 'react';
import Spinner from "./Spinner";

const Container = ({ isLoading, children }) => {
    const content = isLoading ? <Spinner/> :  children
    return (
        <>
            {content}
        </>
    );
};

export default Container;