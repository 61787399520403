import React, { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { DateRangePicker } from "react-dates";
import Container from "components/Container";
import { Link } from "react-router-dom";
import { createLoadingSelector } from "../../state/selectors/loadingSelector";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { useForm } from "react-hook-form";
import { userListRequested } from "./userSlice";

export const UserList = () => {
    const loadingSelector = createLoadingSelector(['USERS_LIST']);
    const users = useSelector(state => state.users.paged);
    const userListLoading = useSelector(state => loadingSelector(state));
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let search = queryString.parse(location.search);
    delete search.pageLimit;
    delete search.pageNumber;
    const { register, handleSubmit, reset, setValue } = useForm();
    const [showFilters, setShowFilters] = useState(false);
    const [pageLimit, setPageLimit] = useState(search.pageLimit ? parseInt(search.pageLimit) : 10);
    const [pageNumber, setPageNumber] = useState(search.pageNumber ? parseInt(search.pageNumber) : 1);
    const [filterCount, setFilterCount] = useState(0);
    const [startDate, setStartDate] = useState(search.dateFrom && moment(search.dateFrom));
    const [endDate, setEndDate] = useState(search.dateTo && moment(search.dateTo));
    const [dateFocus, setDateFocus] = useState();
    useEffect(() => {
        register({ name: "dateTo", value: "" });
        register({ name: "dateFrom", value: "" });
    }, [register]);
    const onSubmit = values => {
        let search = { ...values, pageNumber: 1, pageLimit };
        let query = queryString.stringify(search, {
            skipEmptyString: true
        });
        setPageNumber(1);
        dispatch(userListRequested({ pageNumber: 1, pageLimit, filter: values }));
        history.push({
            pathname: window.location.pathname,
            search: query
        });
        setShowFilters(false);
        let count = 0;
        _.values(values).forEach(item => item && count++)
        setFilterCount(count);
    }
    const resetFilters = () => {
        let query = queryString.stringify({ pageNumber: 1, pageLimit })
        setPageNumber(1);
        dispatch(userListRequested({ pageNumber: 1, pageLimit }));
        history.push({
            pathname: window.location.pathname,
            search: query
        });
        reset({ region: "" });
        setStartDate(null);
        setEndDate(null);
        setFilterCount(0);
        setShowFilters(false);
    }
    useEffect(() => {
        async function loadUsers() {
            let count = 0;
            _.values(search).forEach(item => item && count++)
            setFilterCount(count);
            search.pageNumber = pageNumber.toString();
            search.pageLimit = pageLimit.toString();
            let query = queryString.stringify(search, { skipEmptyString: true });
            history.push({
                pathname: window.location.pathname,
                search: query
            });
            dispatch(userListRequested({ pageNumber, pageLimit, filter: search }));
        }

        loadUsers().then();
    }, [pageLimit, pageNumber])
    return (<>
        <div className="breadcrumb-holder container-fluid">
            <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                <li className="breadcrumb-item active">Users</li>
            </ul>
        </div>
        <section className="tables">
            <div className="container-fluid">
                <div className="card">
                    <div className="table-card-close d-flex mt-2">
                        <span className="mr-1 align-self-center">Items Per page </span>
                        <select
                            className="form-control w-auto"
                            value={pageLimit}
                            onChange={event => {
                                setPageNumber(1);
                                setPageLimit(parseInt(event.target.value));
                            }}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                    <div className="card-header d-flex align-items-center">
                        <h3 className="h4">
                            Users
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Container isLoading={userListLoading}>
                                <table className="table table-striped table-hover table-responsive-sm h-100">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Full Name</th>
                                        <th>Region</th>
                                        <th>Email</th>
                                        <th>Phone No</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.items.length > 0 && users.items.map(user => (
                                        <tr key={user.id}>
                                            <th scope="row">{user.id}</th>
                                            <td>{`${user.firstName ?? ""} ${user.lastName ?? ""}`}</td>
                                            <td>{user.region?.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phoneNumber}</td>
                                            <td><Link to={`/users/${user.id}`}><i className="las la-eye la-2x"/></Link></td>
                                        </tr>
                                    ))}
                                    {users.items.length === 0 && (
                                        <tr>
                                            <td colSpan={10} className="text-center">No Records Found</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </Container>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <div className="pagination-info"
                                     aria-live="polite">
                                    {users.totalCount > 0 && <>Showing 1 to 10
                                        of {users.totalCount} entries</>}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                {users.items.length > 0 &&
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'page-link'}
                                    initialPage={pageNumber - 1}
                                    forcePage={pageNumber - 1}
                                    pageCount={users.totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={({ selected }) => setPageNumber(selected + 1)}
                                    containerClassName={'pagination'}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    activeClassName={'active'}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}