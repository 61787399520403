import React, {useState} from 'react';
import {Header} from "./Header";
import {Navbar} from "./Navbar";

const Layout = (props) => {
    const [navbarClosed, setNavbarClosed] = useState(false);
    return (
        <div className="page">
            <Header navbarClosed={navbarClosed} setNavbarClosed={setNavbarClosed}/>
            <div className="page-content d-flex align-items-stretch">
                <Navbar navbarClosed={navbarClosed}/>
                <div className="content-inner">
                    {props.children}
                    <footer className="main-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>Your company &copy; 2017-2020</p>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <p>
                                        Design by <a href="https://bootstrapious.com/p/admin-template"
                                                     className="external">Bootstrapious</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>);
};

export {Layout};