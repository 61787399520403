import { api } from "./api";
import { getAccessToken } from "./accessTokenService";

export const fetchScratchCards = async (pageNumber, pageSize, filter) => {
    return await api.get('scratchCard/', {
        searchParams: { pageNumber, pageSize, ...filter },
        headers: {
            "authorization": `Bearer ${getAccessToken()}`,
        }
    }).json();
}
export const authorizeScratchCards = async data => {
    return await api.post('scratchCard/authorize', {
        json: data,
        headers: {
            "authorization": `Bearer ${getAccessToken()}`,
        }
    }).json();
}