import ky from 'ky';
import { userManager } from "auth/userManager";
import { getAccessToken } from "./accessTokenService";

export const api = ky.create({
    retry: {
        limit: 3,
        methods: ['get', 'post', 'options'],
        statusCodes: [401]
    },
    hooks: {
        beforeRetry: [
            async ({ request, options, error, retryCount }) => {
                try{
                    let user = await userManager.signinSilent();
                    request.headers.set('Authorization', `Bearer ${getAccessToken()}`);    
                }
                catch (e){
                    await userManager.signinRedirect();
                }
            }
        ]
    }
})