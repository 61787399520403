import { createReducer, createAction } from "@reduxjs/toolkit";

export const userListRequested = createAction("USERS_LIST_REQUEST");
export const userListLoaded = createAction("USERS_LIST_SUCCESS");
export const userListFailure = createAction("USERS_LIST_FAILURE");
export const userListClear = createAction("USERS_LIST_CLEAR");
export const userCreate = createAction("USER_CREATE");

const reducer = createReducer({
    paged: {
        pageIndex: 0,
        totalPages: 0,
        totalCount: 0,
        items: []
    },
}, builder => {
    builder.addCase(userListLoaded, (state, action) => {
        state.paged = action.payload;
    }).addCase(userListClear, (state, action) => {
        state.paged = {
            pageIndex: 0,
            totalPages: 0,
            totalCount: 0,
            items: []
        };
    })
})
export default reducer;
