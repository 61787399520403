import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthProvider } from 'oidc-react';
import { history } from "./browserHistory";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./state/store";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import './assets/css/fontastic.css';
import './assets/css/style.default.css';
import "./index.css";
import { userManager } from "./auth/userManager";
import { userLoggedIn } from "./state/reducers/authReducer";

const oidcConfig = {
    onSignIn: user => {
        store.dispatch(userLoggedIn(user));
        // Redirect?
        history.push("/");
    },
    userManager: userManager,
};

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <AuthProvider {...oidcConfig}>
                <Router history={history}>
                    <App/>
                </Router>
            </AuthProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
