import {combineReducers} from "redux";
import messageReducer from "features/message/messageSlice";
import userReducer from "features/users/userSlice";
import scratchCardReducer from "./scratchCardReducer";
import { loadingReducer } from "./loadingReducer";
import authReducer from "./authReducer";

export default combineReducers({
    messages: messageReducer,
    users: userReducer,
    auth: authReducer,
    scratchCards: scratchCardReducer,
    loading: loadingReducer
});
