import { createReducer, createAction } from "@reduxjs/toolkit";

export const messagesRequested = createAction("MESSAGES_REQUEST");
export const messagesLoaded = createAction("MESSAGES_SUCCESS");
export const messagesFailure = createAction("MESSAGES_FAILURE");
export const messagesClear = createAction("MESSAGES_CLEAR");

const reducer = createReducer({
    sms: {
        pageIndex: 0,
        totalPages: 0,
        totalCount: 0,
        items: []
    },
}, builder => {
    builder.addCase(messagesLoaded, (state, action) => {
        state.sms = action.payload;
    }).addCase(messagesClear, (state, action) => {
        state.sms = {
            pageIndex: 0,
            totalPages: 0,
            totalCount: 0,
            items: []
        };
    })
})
export default reducer;
