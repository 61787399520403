import {api} from "./api";
import { getAccessToken } from "./accessTokenService";

export const fetchMessages = async (pageNumber, pageSize, filter) => {
    return await api.get('message/', { 
        searchParams: { pageNumber, pageSize, ...filter },
        headers: {
            "authorization" : `Bearer ${getAccessToken()}`,
        }
    }).json();
}