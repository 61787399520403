import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

const Navbar = ({ navbarClosed }) => {
    const location = useLocation()
    let locationSplit = location.pathname.split("/");
    let path = "";
    if(locationSplit.length > 1){
        path = locationSplit[1];
    }
    const [dropDownOpen, setDropDownOpen] = useState(path);
    const openCloseDropDown = dropDown => {
        if (dropDownOpen === dropDown) {
            setDropDownOpen("");
            return;
        }
        setDropDownOpen(dropDown);
    }
    return (
        <nav className={clsx("side-navbar", { "shrinked": navbarClosed })}>
            <div className="sidebar-header d-flex align-items-center">
                <div className="avatar">
                    <img src={require("assets/img/avatar-1.jpg")}
                         alt="..." className="img-fluid rounded-circle"/>
                </div>
                <div className="title">
                    <h1 className="h4">Mark Stephen</h1>
                    <p>Web Designer</p>
                </div>
            </div>
            <span className="heading">Main</span>
            <ul className="list-unstyled">
                <li>
                    <NavLink to={"/"} activeClassName={"active"} exact={true}>
                        <i className="icon-home"/>Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/messages" activeClassName={"active"}>
                        <i className="las la-sms"/>Messages
                    </NavLink>
                </li>
                <li>
                    <a aria-expanded="false"
                       className={clsx({ "active": location.pathname.includes("/scratch-cards") })}
                       onClick={() => openCloseDropDown("scratch-cards")}>
                        <i className="las la-credit-card"/>Scratch Cards
                    </a>
                    <ul id="scratch-card-list"
                        className={clsx("collapse list-unstyled", { "show": dropDownOpen === "scratch-cards" })}>
                        <li><NavLink to="/scratch-cards" exact>List</NavLink></li>
                        <li><NavLink to="/scratch-cards/authorize" exact>Authorize</NavLink></li>
                    </ul>
                </li>
                <li>
                    <a aria-expanded="false"
                       className={clsx({ "active": location.pathname.includes("/users") })}
                       onClick={() => openCloseDropDown("users")}>
                        <i className="las la-user"/>Users
                    </a>
                    <ul id="scratch-card-list"
                        className={clsx("collapse list-unstyled", { "show": dropDownOpen === "users" })}>
                        <li><NavLink to="/users" exact>List</NavLink></li>
                        <li><NavLink to="/users/create" exact>Create</NavLink></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export { Navbar };